<template>
  <div>
    <el-table row-key="key" header-row-class-name="custom-table-header" v-loading="tableLoading" :data="tableData">
      <el-table-column label="产品编号" prop="id" width="100">
        <template #default="{row}">
          <label>{{row.id}}</label>
        </template>
      </el-table-column>
      <el-table-column label="酒卡包含" prop="action" width="100">
        <template #default="{row}">
          <span>
            <el-switch v-model="row.canBuy" ctive-color="#13ce66" inactive-color="#ff4949"></el-switch>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="品名" prop="name">
        <template #default="{row}">
          <el-input v-model="row.title" placeholder="产品名称" />
        </template>
      </el-table-column>
      <el-table-column label="数量" prop="count">
        <template #default="{row}">
          <el-input-number type="text" v-model="row.count" placeholder="产品数量" />
        </template>
      </el-table-column>
      <el-table-column label="单位" prop="unit">
        <template #default="{row}">
          <el-input v-model="row.unit" placeholder="产品计量单位" />
        </template>
      </el-table-column>
      <el-table-column label="操作" prop="action" width="200">
        <template #default="{row}">
          <el-button type="text" @click="remove(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-button style="width: 100%;margin-top: 16px; border-style: dashed;" @click="newTableData">
      <i class="el-icon-plus"></i>
      新增内容
    </el-button>
  </div>
</template>
<script>
export default {
  data () {
    return {
      tableLoading: false,
      tableData: [

      ],
    }
  },
  methods: {
    DataBinding (obj) {
      if (obj && obj.length > 0) {
        this.tableData = obj;
      }
    },
    remove (row) {
      var index = this.tableData.indexOf(row);
      this.tableData.splice(index, 1);
    },
    //新增数据
    newTableData () {
      this.tableData.push({
        id: 0,
        title: '',
        count: 0,
        unit: '',
        canBuy: true,
      });
    }
  }
}
</script>