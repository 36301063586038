<template>
  <div class="main-conent main-conent-minheight">
    <el-form label-position="right" :model="formData" label-width="130px" v-loading.lock="loading" :rules="formRule" ref="editForm" class="demo-ruleForm">
      <el-card shadow="never" class="cus-card">
        <template #header>基础信息</template>
        <el-form-item label="活动名称" prop="title">
          <el-input v-model="formData.title"></el-input>
        </el-form-item>
        <el-form-item label="开启状态">
          <el-switch v-model="formData.isOpen" ctive-color="#13ce66" inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
        <el-form-item label="允许优惠券购买">
          <el-switch v-model="formData.canCoupon" ctive-color="#13ce66" inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
        <el-form-item label="销售价格" prop="totalPrice">
          <el-input-number v-model="formData.totalPrice"></el-input-number>
        </el-form-item>
        <el-row>
          <el-col :lg="8" :md="12" :sm="24">
            <el-form-item label="开始日期" prop="startDate">
              <el-date-picker v-model="formData.startDate" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :lg="8" :md="12" :sm="24">
            <el-form-item label="结束日期" prop="endDate">
              <el-date-picker v-model="formData.endDate" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="排序" prop="sort">
          <el-input-number v-model="formData.sort"></el-input-number>
        </el-form-item>
      </el-card>
      <el-card shadow="never" class="">
        <el-button type="primary" @click="Submit('editForm')" :loading="loading">
          提交
        </el-button>
        <el-button @click="RefreshPage">
          刷新
        </el-button>
      </el-card>
    </el-form>
  </div>
</template>
<script>
import { queryActivityInfo, editActivityInfo } from '@/service/activity.js';
import { activityFormRule } from '@/utlis/rules/activityRule.js';
import ActivityProcuctEditer from '@/components/Table/ActivityProcuctEditer.vue';
export default {

  data () {
    return {
      activeId: 0,
      formRule: activityFormRule,
      formData: {
        id: 0,
        title: '',
        isOpen: true,
        totalPrice: 0,
        sort: 99,
        canCoupon: false,
        startDate: new Date(),
        endDate: new Date(),
      },
      loading: false,
    }
  },
  components: { ActivityProcuctEditer },
  created () {
    this.activeId = this.$route.query.activeId;
    this.DataBinding();
  },
  methods: {
    //重置、刷新
    RefreshPage () {
      this.DataBinding();
    },
    //加载数据
    DataBinding () {
      if (this.activeId && this.activeId > 0) {
        this.loading = true;
        queryActivityInfo(this.activeId).then(res => {
          this.formData = res.data;
          this.loading = false;
        }).catch(ex => {
          this.loading = false;
          this.$notify({
            title: '警告',
            message: '当前活动没有数据可供查询',
            type: 'warning'
          });
          this.$route.go(-1);
        })
      }
    },
    //提交表单
    Submit (formName) {
      this.loading = true;
      //表单数据验证
      this.$refs[formName].validate(valid => {
        if (valid) {
          editActivityInfo(this.formData).then(res => {
            if (res.code == 0) {
              this.$message({
                message: '保存成功',
                type: 'success'
              });
              this.$router.go(-1);
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
      this.loading = false;
    }
  }
}
</script>
<style scoped lang="scss">
.CardHeaderDesc {
  font-size: 12px;
  color: gray;
  margin-left: 20px;
}
</style>