var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],attrs:{"row-key":"key","header-row-class-name":"custom-table-header","data":_vm.tableData}},[_c('el-table-column',{attrs:{"label":"产品编号","prop":"id","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('label',[_vm._v(_vm._s(row.id))])]}}])}),_c('el-table-column',{attrs:{"label":"酒卡包含","prop":"action","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_c('el-switch',{attrs:{"ctive-color":"#13ce66","inactive-color":"#ff4949"},model:{value:(row.canBuy),callback:function ($$v) {_vm.$set(row, "canBuy", $$v)},expression:"row.canBuy"}})],1)]}}])}),_c('el-table-column',{attrs:{"label":"品名","prop":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"产品名称"},model:{value:(row.title),callback:function ($$v) {_vm.$set(row, "title", $$v)},expression:"row.title"}})]}}])}),_c('el-table-column',{attrs:{"label":"数量","prop":"count"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input-number',{attrs:{"type":"text","placeholder":"产品数量"},model:{value:(row.count),callback:function ($$v) {_vm.$set(row, "count", $$v)},expression:"row.count"}})]}}])}),_c('el-table-column',{attrs:{"label":"单位","prop":"unit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"placeholder":"产品计量单位"},model:{value:(row.unit),callback:function ($$v) {_vm.$set(row, "unit", $$v)},expression:"row.unit"}})]}}])}),_c('el-table-column',{attrs:{"label":"操作","prop":"action","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.remove(row)}}},[_vm._v("删除")])]}}])})],1),_c('el-button',{staticStyle:{"width":"100%","margin-top":"16px","border-style":"dashed"},on:{"click":_vm.newTableData}},[_c('i',{staticClass:"el-icon-plus"}),_vm._v(" 新增内容 ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }